import { Alert, Button, Checkbox, Collapse, List, Select, Space } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles/checksList.scss';
import { useQuery } from 'react-query';
import {
  getCachedCurrentUser,
  queryKeyChecks,
  refreshChecks,
} from '../helpers/storeHelper';
import {
  accountForId,
  accountFullName,
  convertApiDateStringToDate,
  convertDateToApiDateString,
  showGenericOperationFailedMessage,
} from '../../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import DatePicker from '../shared/DatePicker';
import { isEmpty } from 'lodash';
import CurrencyIcon from '../shared/Currency';
import { CheckStatus, Currency } from '../../@types/enums';
import { ChecksFilters, listChecksApi } from '../../api/checks';
import LoadingSpinner from '../shared/LoadingSpinner';
import CheckCard from './CheckCard';
import CheckFormModal from './CheckFormModal';
import { downloadCSV } from '../../utils/csvHelpers';
import { Check } from '../../@types/Check';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface ChecksListProps {}

interface ChecksListState {
  currentPage: number;
  filters: ChecksFilters;
  checkFormModalVisible: boolean;
  csvLoading: boolean;
}

const DEFAULT_PAGE_SIZE = 10;

const ChecksList = ({}: ChecksListProps) => {
  const { t } = useTranslation();
  const currentUser = getCachedCurrentUser();

  const initialState = (): ChecksListState => {
    return {
      currentPage: 1,
      filters: {},
      checkFormModalVisible: false,
      csvLoading: false,
    };
  };

  const [state, setState] = useState<ChecksListState>(initialState());

  const {
    isLoading,
    error,
    data: checksListResponse,
    isPreviousData,
  } = useQuery(
    queryKeyChecks().concat([
      state.currentPage.toString(),
      JSON.stringify(state.filters),
    ]),
    () =>
      listChecksApi(state.filters, {
        page: state.currentPage,
        pageSize: DEFAULT_PAGE_SIZE,
      }),
    {
      keepPreviousData: true,
      useErrorBoundary: false,
      onError: (e) => setCurrentPage(1),
    },
  );

  const allTags = checksListResponse?.data?.all_tags || [];

  const isFiltersEmpty = () => isEmpty(state.filters);

  const onPageChange = (page: number) => setCurrentPage(page);

  const setCurrentPage = (page: number) => {
    setState((state) => ({ ...state, currentPage: page }));
  };

  const closeModals = (saved = false) => {
    setState((state) => ({
      ...state,
      currentPage: saved ? 1 : state.currentPage,
      checkFormModalVisible: false,
    }));
  };

  /*
   * Add Check
   */

  const onSaveCheck = async (mutationInfo: any) => {
    await refreshChecks();
    closeModals(true);
  };

  const checkFormModal = state.checkFormModalVisible && (
    <CheckFormModal
      onSave={onSaveCheck}
      onCancel={closeModals}
      allTags={allTags}
    />
  );

  const openCheckFormModal = (event: any) => {
    setState((state) => ({
      ...state,
      checkFormModalVisible: true,
    }));
  };

  const addChecksButton = (
    <div className="checks-list-component__add-checks">
      <Button
        className="checks-list-component__add-checks__button"
        onClick={openCheckFormModal}
      >
        <FontAwesomeIcon icon={faPlus} color="green" />
        <span>{t('check.actions.add')}</span>
      </Button>
    </div>
  );

  /*
   * Filters
   */

  const checksDateFilter = (filterField: 'start_date' | 'end_date') => (
    <DatePicker
      allowClear={true}
      className="checks-filters__input__date"
      value={
        state.filters[filterField]
          ? convertApiDateStringToDate(state.filters[filterField]!)
          : undefined
      }
      format="YYYY/MM/DD"
      name={filterField}
      placeholder={
        filterField === 'start_date'
          ? t('check.filters.startDate')
          : t('check.filters.endDate')
      }
      onChange={(selectedDate: Date, dateString: string | string[]) =>
        onDateFilterChange(filterField, selectedDate)
      }
    />
  );

  const onDateFilterChange = (
    filterField: 'start_date' | 'end_date',
    selectedDate: Date | null,
  ) => {
    setState((oldState) => {
      const filters = { ...oldState.filters };
      if (selectedDate) {
        filters[filterField] = convertDateToApiDateString(selectedDate);
      } else {
        delete filters[filterField];
      }
      return {
        ...oldState,
        filters,
      };
    });
  };

  const onTagsFilterChange = (tags: string[]) => {
    setState((oldState) => {
      const filters = { ...oldState.filters };
      filters.tags = tags || [];
      return {
        ...oldState,
        filters,
      };
    });
  };

  const onEnforceTagsIntersectionFilterChange = (e: CheckboxChangeEvent) => {
    setState((oldState) => {
      const filters = { ...oldState.filters };
      filters.enforce_tags_intersection = e.target.checked;
      return {
        ...oldState,
        filters,
      };
    });
  };

  const onCheckStatusFilterChange = (status: CheckStatus) => {
    setState((oldState) => {
      const filters = { ...oldState.filters };
      if (status) {
        filters.status = status;
      } else {
        delete filters.status;
      }
      return {
        ...oldState,
        filters,
      };
    });
  };

  const clearFilters = async () => setState(initialState());

  const checksFilters = (
    <div className="checks-filters">
      <Space className="checks-filters__space">
        <div className="checks-filters__input">
          {checksDateFilter('start_date')}
        </div>
        <div className="checks-filters__input">
          {checksDateFilter('end_date')}
        </div>
        <div className="checks-filters__input">
          <Select
            style={{ width: '130px' }}
            placeholder={t('check.filters.status')}
            onChange={onCheckStatusFilterChange}
            value={state.filters.status || undefined}
            options={Object.values(CheckStatus).map((status) => ({
              label: t(`check.checkStatuses.${status}`),
              value: status,
            }))}
          />
        </div>
        <div className="checks-filters__input">
          <Select
            mode="multiple"
            style={{ width: '164px' }}
            placeholder={t('check.filters.tags')}
            onChange={onTagsFilterChange}
            value={state.filters.tags || []}
            options={allTags.map((tag) => ({
              label: tag,
              value: tag,
            }))}
          />
        </div>
        <Space className="checks-filters__space">
          <div className="checks-filters__input">
            <Checkbox
              checked={state?.filters!.enforce_tags_intersection}
              onChange={onEnforceTagsIntersectionFilterChange}
            >
              {t('check.filters.enforceTagsIntersection')}
            </Checkbox>
          </div>
        </Space>
        <div className="checks-filters__input">
          <Button
            type="primary"
            onClick={clearFilters}
            disabled={isLoading || isFiltersEmpty()}
          >
            <FontAwesomeIcon
              icon={faX}
              color="white"
              style={{ margin: '0px 5px' }}
            />
          </Button>
        </div>
      </Space>
    </div>
  );

  const checksFiltersSection = (
    <Collapse
      defaultActiveKey={[1]}
      className="checks-list-collapsible"
      key="0-1"
      items={[
        {
          key: `collapse_panel_checks_filter`,
          label: t('check.words.filters'),
          children: checksFilters,
        },
      ]}
    />
  );

  const prepareCSVRows = async () => {
    setState((oldState) => ({ ...oldState, csvLoading: true }));

    // export functionality should be only available to singleAccountMode
    let checks = [] as Check[];
    const csvRows = [[]] as [string[]];

    try {
      const csvChecksResponse = await listChecksApi(state.filters, {
        disablePagination: true,
      });
      checks = csvChecksResponse?.data?.checks || [];
    } catch (error) {
      setState((oldState) => ({ ...oldState, csvLoading: false }));
      return csvRows;
    }

    // headers
    csvRows.push([
      t('check.words.description'),
      t('check.words.date'),
      t('check.words.amount'),
      t('check.words.checkStatus'),
      t('check.words.fromAccount'),
      t('check.words.toAccount'),
    ]);

    // data
    checks.forEach((check) => {
      console.log(
        't(`check.checkStatuses.${check.status}`)',
        t(`check.checkStatuses.${check.status}`),
      );
      csvRows.push([
        check.description || '',
        check.date,
        Number(check.amount || 0).toLocaleString(),
        t(`check.checkStatuses.${check.status}`),
        accountFullName(accountForId(check.from_account_id)!),
        accountFullName(accountForId(check.to_account_id)!),
      ]);
    });

    // a couple of empty rows
    csvRows.push(['', '']);
    csvRows.push(['', '']);

    // total sum
    csvRows.push([t('stats.totalBalance')]);
    csvRows.push(['', '']);

    const filteredBalances = checksListResponse!.data!.filtered_balances || {};
    for (const status of Object.keys(filteredBalances)) {
      const statusBalances = filteredBalances[status];
      csvRows.push([
        t(`check.checkStatuses.${status}`),
        Number(statusBalances.count).toLocaleString(),
      ]);

      for (const currency of Object.keys(statusBalances.currencies)) {
        // total sum amount per currency
        csvRows.push([
          t(`account.currency.${currency}`),
          Number(statusBalances.currencies[currency] || 0).toLocaleString(),
        ]);
      }

      csvRows.push(['', '']);
    }

    setState((oldState) => ({ ...oldState, csvLoading: false }));

    return csvRows;
  };

  const exportToCSVButton = () => {
    // export functionality should be only available to singleAccountMode
    const filename = `${t('check.title')}_${new Date().toLocaleDateString()}`;

    return (
      <Button
        className="entity-action-buttons__button"
        onClick={async () => downloadCSV(filename, prepareCSVRows)}
        loading={state.csvLoading}
        disabled={state.csvLoading}
      >
        <FontAwesomeIcon icon={faDownload} color="green" />
      </Button>
    );
  };

  const filteredStatusBalanceStyle = (status: string) =>
    status === CheckStatus.PENDING ? { color: 'gray' } : { color: 'green' };

  const filteredBalanceStatusRow = (status: string, statusBalances: any) => (
    <div
      className="checks-list__info-section__filtered_summary"
      style={filteredStatusBalanceStyle(status)}
    >
      <div className="checks-list__info-section__filtered_summary__status">
        {t(`check.checkStatuses.${status}`)}: ({statusBalances.count})
      </div>
      <div className="checks-list__info-section__filtered_summary__amount">
        {Object.keys(statusBalances.currencies).map((currency) =>
          filteredBalancePerCurrencyRow(
            statusBalances.currencies[currency],
            currency as Currency,
            status,
          ),
        )}
      </div>
    </div>
  );

  const filteredBalancePerCurrencyRow = (
    amount: number,
    currency: Currency,
    status: string,
  ) => (
    <div
      className="checks-list__info-section__filtered_summary__currency"
      style={filteredStatusBalanceStyle(status)}
    >
      <div className="checks-list__info-section__filtered_summary__amount">
        <span>
          <CurrencyIcon currencyType={currency} />
        </span>
        <span className="checks-list__info-section__filtered_summary__amount__value">
          {Number(amount || 0).toLocaleString()}
        </span>
      </div>
    </div>
  );

  const filteredBalancesSection = () => {
    const filteredBalances = checksListResponse!.data!.filtered_balances || {};
    return (
      <div className="checks-list__info-section__filtered_summary_div">
        <span>
          {isFiltersEmpty()
            ? t('check.list.totalBalances')
            : t('check.list.filteredBalances')}
          :{' '}
        </span>
        {Object.keys(filteredBalances).map((checkStatus) => {
          const statusBalances = filteredBalances[checkStatus];
          return filteredBalanceStatusRow(checkStatus, statusBalances);
        })}
      </div>
    );
  };

  const checksListInfoSection = () => (
    <div className="checks-list__info-section">
      <div className="checks-list__info-section__action-buttons">
        {exportToCSVButton()}
      </div>
      {filteredBalancesSection()}
    </div>
  );

  if (error) showGenericOperationFailedMessage(error, t);
  if (isLoading) return <LoadingSpinner />;

  return (
    <div className="checks-list-component">
      {addChecksButton}
      {checksFiltersSection}
      {!!error && (
        <Alert message={t('generic.errors.dataIncomplete')} type={'error'} />
      )}
      {!isEmpty(checksListResponse?.data?.checks) && checksListInfoSection()}
      <div className="checks-list">
        <List
          key={'checks_list_paginated'}
          itemLayout="vertical"
          loading={isLoading}
          pagination={{
            current: state.currentPage,
            onChange: onPageChange,
            pageSize: DEFAULT_PAGE_SIZE,
            defaultPageSize: DEFAULT_PAGE_SIZE,
            total: checksListResponse?.meta?.count || 0,
            disabled: isLoading || isPreviousData,
          }}
          dataSource={checksListResponse?.data?.checks || []}
          renderItem={(check) => (
            <List.Item key={check.id} className="checks-list__item">
              <CheckCard
                key={`check_card_in_list_for_${check.id}`}
                check={check}
                allTags={allTags}
                editMode={check.user_id == currentUser.id}
                filterApplied={!isFiltersEmpty()}
              />
            </List.Item>
          )}
        />
      </div>
      {checkFormModal}
    </div>
  );
};

export default ChecksList;

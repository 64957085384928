import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from './apiHelper';
import { API_SERVER } from '../utils/envVars';
import { CheckStatus } from '../@types/enums';
import { Check, ChecksListResponse } from '../@types/Check';

export interface ChecksFilters {
  from_account_id?: number;
  to_account_id?: number;
  start_date?: string;
  end_date?: string;
  tags?: string[];
  enforce_tags_intersection?: boolean;
  status?: CheckStatus;
}

export interface CheckParams {
  from_account_id: number;
  to_account_id: number;
  amount: number;
  description: string | null;
  date: string;
  tags?: string[];
}

export const listChecksApi = async (
  filters: ChecksFilters,
  {
    page,
    pageSize,
    disablePagination = false,
  }: { page?: number; pageSize?: number; disablePagination?: boolean } = {},
) => {
  const params = { ...filters } as any;
  if (page) {
    params.page = page;
  }
  if (pageSize) {
    params.items = pageSize;
  }
  if (disablePagination) {
    params.disable_pagination = disablePagination;
  }

  const apiResponse = await getRequest<ChecksListResponse>(
    `${API_SERVER}/checks`,
    {
      ...filters,
      ...params,
    },
  );
  return apiResponse!;
};

export const createCheckApi = async (checkParams: CheckParams) => {
  const apiResponse = await postRequest<Check>(`${API_SERVER}/checks`, {
    check: checkParams,
  });
  return apiResponse.data!;
};

export const updateCheckApi = async (id: number, checkParams: CheckParams) => {
  const apiResponse = await putRequest<Check>(`${API_SERVER}/checks/${id}`, {
    check: checkParams,
  });
  return apiResponse.data!;
};

export const deleteCheckApi = async (id: number) =>
  deleteRequest(`${API_SERVER}/checks/${id}`);
